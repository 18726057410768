import { useEffect } from "react";
import "url-search-params-polyfill";
import useSearchParams from "@src/utils/useSearchParams";

function Invite() {
  const [classCode] = useSearchParams("c");
  const [schoolCode] = useSearchParams("s");

  useEffect(() => {
    if (schoolCode) {
      window.location.replace(`https://home.classdojo.com/#/classInvite/?s=${schoolCode}`);
    }
    if (classCode) {
      window.location.replace(`https://home.classdojo.com/#/classInvite/?c=${classCode}`);
    }
  }, [classCode, schoolCode]);

  return null;
}

export default Invite;
